/* Container */
.container {
  display: flex;
  padding: 10px 15px;
  height: 100%;
  flex-direction: column;
}

/* Body */
.body {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 5px;
}
.leftSectionConcepts {
  display: flex;
  flex-direction: column;
  width: 150px;
}

.rightSectionValues {
  display: flex;
  flex-direction: column;
  width: 150px;
}

/* Footer */
.footerPriceAction {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 10px;
}
