@import url("https://fonts.googleapis.com/css?family=Eczar|Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.Raleway {
  font-family: Raleway;
}

.overflow {
  overflow: auto;
}

.dashboard {
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 0 4px white;
  width: 304px;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Raleway";
}

.container-index {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.container-index-upper {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
}

.container-index-lower {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.65);
  overflow: auto;
}

.desktop-use-not-allowed {
  font-family: Muli;
  font-size: 25.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.style-toolbar-buttons {
  color: rgba(255, 255, 255, 0.54);
}

.standard-toolbar-buttons:hover {
  color: white;
}

.page__content {
  overflow-x: hidden;
}

.scrollable {
  overflow: auto;
}

.scrollable::-webkit-scrollbar {
  display: none;
}

/* GLOBALS STYLES */

/*********************ANT TABS******************************/
.ant-tabs-nav-container {
  /* background-color: #2c2c36; */
}

.ant-tabs-nav-container-scrolling {
  /* padding: 0 */
}

.ant-tabs-tab-prev-icon {
  /* color: white; */
}

.ant-tabs-tab-next-icon {
  /* color: white; */
}

.ant-tabs-nav-scroll {
  /* background-color: #2c2c36; */
}

.ant-tabs-tab {
  color: white; /*TODO: Ya esta configurado el Componente Tabs... Borrar esta línea cuando termine de migrar todas las Tabs y TabPane*/
}

.ant-tabs-content {
  /* height: 100%; */
}

/*********************ANT CARD****************************/
.ant-card-body {
  background-color: transparent;
  padding: 5px 0;
}

.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0;
}

.ant-card-bordered {
  border: none;
}

/*********************ANT MODALS****************************/
.modal-title {
  font-family: Raleway;
  font-size: 16.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: 0.15px;
  color: white;
}

/*********************ANT POPOVER****************************/
.ant-popover-title {
  font-family: Raleway;
  font-size: 16.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: 0.15px;
}

/*********************ANT CALENDAR PICKER INPUT***********************/
.ant-calendar-picker-clear {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
}

.ant-calendar-picker-clear:hover {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
}

.ant-calendar-picker-icon {
  color: rgba(255, 255, 255, 0.6);
}

/*********************ANT TIME PICKER INPUT***********************/
.ant-time-picker-clear {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
}

.ant-time-picker-clear:hover {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
}

.ant-time-picker-icon .ant-time-picker-clock-icon {
  color: rgba(255, 255, 255, 0.6);
}

/*********************ANT SELECT ARROW************************/
.ant-select-selection {
  background-color: transparent;
}
.ant-select-arrow {
  color: rgba(255, 255, 255, 0.6);
}

#icon-standard {
  color: rgba(255, 255, 255, 0.6);
}

/*********************ANT CHECKBOX************************/
.ant-checkbox {
  /* border: rgba(255, 255, 255, 0.6) solid 1px; */
}

.ant-checkbox-inner {
  /* background-color: transparent; */
  /* border-color: #2c2c36; */
  /* border-color: rgba(255, 255, 255, 0.3); */
}

.ant-checkbox-wrapper {
  /* width: 260px;
  height: 33px;
  font-family: Raleway;
  font-size: 16.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: 0.15px;
  color: white;
  margin: 0 !important; */
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(0, 139, 82);
  color: rgb(59, 68, 74);
}

/*********************ANT COLLAPSE************************/
.ant-collapse-borderless {
  /* background-color: transparent; */
  border: none;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

/*********************ANT SPIN************************/
.ant-spin-nested-loading {
  height: 100%;
}

/*********************ANT NOTIFICATION************************/
.ant-notification {
  margin-right: 10px !important;
}

.ant-notification-notice {
  margin-bottom: 10px !important;
}
.ant-notification-notice-close {
  color: white !important;
}

.ant-notification-notice-message {
  color: #ececf9 !important;
}

.ant-notification-notice-description {
  font-family: Raleway !important;
  font-size: 13px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.41 !important;
  letter-spacing: 0.25px !important;
}

/*********************ANT RADIO BUTTON************************/
.ant-radio-button-wrapper {
  color: white;
  background-color: transparent !important;
}

.ant-radio-button-wrapper-checked {
  color: #40a9ff;
}

/*********************ANT DIVIDER************************/
.ant-divider-horizontal {
  margin: 0;
}

/*********************ANT BREADCRUMB************************/
.ant-breadcrumb {
  color: rgba(255, 255, 255, 0.6);
}

.ant-breadcrumb-separator {
  color: rgba(255, 255, 255, 0.6);
}

/*********************BUTTON*****************************/
.button-style-primary {
  /* width: 96px; */
  height: 36px;
  font-family: Raleway;
  font-size: 14.1px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: 1.25px;
  text-align: center;
  background-color: #1eb980;
  color: rgba(0, 0, 0, 0.87);
}

/*********************VARIOUS*****************************/
.form-item-standard {
  margin-bottom: 10px;
  max-width: 260px;
}
/************************************************************/

.txt-white {
  color: white;
}

.item-none-display {
  display: none;
}

@media (orientation: portrait) {
  .container-index-upper {
    height: 40%;
  }
  .container-index-lower {
    height: 60%;
    padding-top: 15px;
  }
}

@media (orientation: landscape) {
  .container-index {
    display: flex;
  }
  .container-index-lower {
    height: 100%;
    justify-content: center;
    margin-top: 0;
  }
}

@media (min-width: 1025px) {
  .container-index {
    display: block;
  }
  .container-index-upper {
    height: 45%;
  }
  .container-index-lower {
    height: 55%;
    justify-content: flex-start;
  }
}
