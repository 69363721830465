/* colors */
@value _primaryColor: black;
@value _secondaryColor: rgba(255,255,255,0.9);
@value _disabledColor: rgba(255, 255, 255, 0.38);
/* background colors */
@value _primaryBackgroundColor: white;
@value _secondaryBackgroundColor: rgba(255, 255, 255, 0.5);
@value _tertiaryBackgroundColor: rgba(255, 255, 255, 0.7);
@value _disabledBackgroundColor: rgb(33,33,33);
@value _transparentBackgroundColor: transparent;

/* Color */
.primaryColor {
	color: _primaryColor;
}
.secondaryColor {
	color: _secondaryColor;
}
.disabledColor {
	color: _disabledColor;
}

/* BackgroundColor */
.primaryBackgroundColor {
	background-color: _primaryBackgroundColor;
}
.secondaryBackgroundColor {
	background-color: _secondaryBackgroundColor;
}
.tertiaryBackgroundColor {
	background-color: _tertiaryBackgroundColor;
}
.disabledBackgroundColor {
	background-color: _disabledBackgroundColor;
}
.transparentBackgroundColor {
	background-color: _transparentBackgroundColor;
}
