.inventory_transaction-container-data-input {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 0 10px 0;
}

.inventory_transaction-data {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 0 10px 0;
}

.inventory_transaction-button {
  width: 30%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 0 10px 0;
}

.inventory_transaction-container-title-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.inventory_transaction-body-container-item {
  display: flex;
  flex-wrap: wrap;
  width: 73vw;
}

.inventory_transaction-body-concept-item {
  font-size: 12px;
  font-family: "Raleway";
  color: black;
  margin-right: 10px;
}

.inventory_transaction-card-creator {
  font-size: 13px;
  font-family: "Raleway";
}

.inventory_transaction-card-creator-clickeable {
  font-size: 13px;
  font-family: "Raleway";
}

.inventory_transaction-card-creator-clickeable:active {
  color: lightskyblue;
}

.inventory_transaction-card-created_at {
  font-family: "Raleway";
  font-size: 12px;
  color: darkgray;
}
