.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  padding: 16px 16px 8px 16px;
  min-height: 100%;
}
/* Header */
.header {
  padding-bottom: 10px;
}

.header span {
  height: 20px;
}

#title {
  height: 24px;
}

.status-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding-top: 25px;
  height: 24px;
}
/* Body */
.body {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px;
  justify-content: space-between;
  padding-right: 10px;
}

.body span {
  height: 25px;
  display: block;
  width: 140px;
}

/* Footer */
.footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 5px 15px 2px 0;
}
