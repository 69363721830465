.wrap {
  width: 100%;
  height: 188px;
  position: absolute;
  top: -8px;
  left: 8px;
  overflow: hidden;
}
.wrap:before, .wrap:after {
  content: ""; 
  position: absolute;
}
.wrap:before {
  width: 40px;
  height: 8px;
  right: 100px;
  background: #4D6530;
  border-radius: 8px 8px 0px 0px;
}
.wrap:after {
  width: 8px;
  height: 40px;
  right: 0px;
  top: 100px;
  background: #4D6530;
  border-radius: 0px 8px 8px 0px;
}

.ribbon6 {
  width: 200px;
  height: 40px;
  line-height: 40px;
  position: absolute;
  top: 30px;
  right: -50px;
  z-index: 2;
  overflow: hidden;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  border: 1px dashed;
  box-shadow:0 0 0 3px #1eb980,  0px 21px 5px -18px rgba(0,0,0,0.6);
  background: rgba(0, 0, 0, 0.60);
  text-align: center;
}

.ribbon {
  font-family: Raleway;
  width: 48%;
  height: 188px;
  position: relative;
  float: left;
  margin-bottom: 30px;
  background-size: cover;
  color: white;
}
.ribbon:nth-child(even) {
  margin-right: 4%;
}
@media (max-width: 500px) {
  .ribbon {
    width: 100%;
  }
  .ribbon:nth-child(even) {
    margin-right: 0%;
  }
}