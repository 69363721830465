.container-notifications {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
}

.container-list-notifications::-webkit-scrollbar {
  display: none;
}
