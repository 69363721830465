#order_history_card-meta {
  padding: 10px;
}

.container-title-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}

.card-creator {
  font-size: 13px;
  font-family: "Raleway";
  color: white;
}

.card-created-at {
  font-family: "Raleway";
  font-size: 12px;
  color: darkgray;
}

.container-description-card {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 0 10px 0;
}

.card-container-item {
  display: flex;
  flex-wrap: wrap;
  /* width: 73vw; */
}

.card-concept {
  font-size: 12px;
  font-family: "Raleway";
  color: lightseagreen;
  margin-right: 10px;
}

.card-value {
  font-size: 12px;
  font-family: "Raleway";
  font-weight: normal;
  color: lightgrey;
  word-break: break-word;
}
