.to_invoice-container-data-input{
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0 0 10px 0;
}

.to_invoice-data{
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 0 10px 0;
}

.to_invoice-button{
  width: 70px;
  padding: 0;
}