/* Container */
.container {
  display: flex;
  justify-content: center;
  min-height: 100%;
  flex-direction: column;
  padding: 10px 15px;
}

/* Header */
.header {
  display: flex;
  color: #000;
  font-size: small;
  width: 100%;
}

.header span {
  width: 208px;
}

/* Body */
.body {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

.conceptAndValue {
  display: flex;
  flex-direction: column;
  max-width: 150px;
}

#counter {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-family: Raleway;
}

.counterContainer {
  align-self: flex-end;
}
