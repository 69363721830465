.pays-subtoolbar-head{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pays-subtoolbar-content{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pays-subtoolbar-subcontent-left{
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pays-subtoolbar-subcontent-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.pays-subtoolbar-subcontent-right{
  padding-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}