@value darkColors: "../../../themes/darkTheme.module.css";
@value lightColors: "../../../themes/lightTheme.module.css";

.card {
	composes: secondaryBackgroundColor from lightColors;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	border-radius: 25px;
	padding: 30px;
	height: 300px;
	width: 300px;
	box-shadow: 0 8px 16px 0 black;
}
.inputs {
	margin-top: 25px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 5px;
	padding: 15px;
	width: 280px;
	height: 146px;
}
.bottoms {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 65px;
}
