.container {
	position: absolute;
	left: 3px;
	bottom: 7px;
	align-self: center;
	justify-content: center;
}

.backgrounBlur {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(10px);
	background-color: rgba(255, 255, 255, 0.5);
}
