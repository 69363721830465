/* Container */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 16px 5px 16px;
}

/* Header */
.headerModal {
  display: flex;
  width: 280px;
  height: 24px;
  align-items: center;
  font-size: 20px;
  margin: 0px 3px 0px 5px;
}

.resumeTitleSpan {
  font-weight: bold;
  font-size: 20px;
}

.headerModalIcons {
  display: flex;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 24px;
}

/* Body */
.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  justify-content: space-between;
  padding-right: 10px;
}

.body span {
  height: 20px;
  display: block;
  width: 100%;
  font-size: 13px;
}

#withoutSignLabel {
  font-size: 11px;
  font-style: italic;
}

.titleContainer {
  margin-bottom: 8px;
}

.producctionContent {
  display: flex;
  flex-direction: column;
}

.ivaAndSubContainer {
  margin-top: 15px;
}

.IvaAndSub {
  display: flex;
}

.list {
  width: 100%;
}

.resumeContainer {
  display: flex;
  flex-direction: column;
}

.resumeBody {
  display: flex;
  gap: 50px;
}
