.card-notification-container-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 73px;
  border-bottom: 0.5px rgb(58, 69, 73) solid;
}

.card-notification-message {
  width: 100%;
  height: 16px;
  display: block;
  text-align: left;
  font-size: smaller;
  color: rgb(179, 190, 189);
  overflow: overlay;
}

.card-notification-message::-webkit-scrollbar {
  display: none;
}

.card-notification-document-id {
  width: 100px;
  height: 20px;
  font-family: "Raleway";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0.1px;
  color: whitesmoke;
  text-align: left;
}

.card-notification-body-details {
  font-size: smaller;
  overflow: auto;
  color: mediumseagreen;
  line-height: 1.2;
  font-family: Raleway;
  height: 24px;
}

.card-notification-details {
  height: 30px;
  overflow: auto;
  font-size: smaller;
  text-align: center;
  word-wrap: break-word;
}

.card-notification-create-details {
  display: flex;
  justify-content: space-between;
}

.card-notification-createdby {
  font-size: 10px;
  font-weight: bold;
}

.card-notification-show {
  font-size: 10px;
}

.card-notification-createdAt {
  color: rgba(255, 255, 255, 0.7);
  font-family: "Raleway";
  font-size: smaller;
}
