.container-list-rows::-webkit-scrollbar{
  display: none;
}

#interpolated-style:nth-child(odd) {
  background-color: #3f3f49;
}

#interpolated-style:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.08);
}

#meta-card-container{
  padding: 10px;
}

#card-container{
  height: 184px;
  border-radius: 2px;
}

.header-rows{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  width: 320px;
  background: #2c2c36;
  color: #ffffff;
  font-family: 'Raleway';
  font-size: medium;
  border-bottom: #3f3f49 solid 1px;
}

.card-company{
  font-size: 12px;
  font-family: 'Raleway';
}

.card-event{
  font-size: 12px;
  font-family: 'Raleway';
}

.card-reprogramAt-concept{
  font-size: 12px;
  font-family: 'Raleway';
}

.card-reprogramAt-value{
  font-size: 12px;
  font-family: 'Raleway';
  font-weight: bold;
}

.card-assign{
  font-size: 12px;
  font-family: 'Raleway';
}

.transfer-leyend-card{
  font-size: 12px;
  font-family: 'Raleway';
}

.container-details{
  display: flex;
}

.card-details{
  width: 180px;
  font-size: 12px;
  font-family: 'Raleway';
}