#order_card-meta {
  height: 242px;
}

.direction-icon {
  background: url("../../../../../../assets/media/img/directions.svg");
  width: 24px;
  height: 24px;
}

.order_card-title {
  /* border: white solid 1px; */
  display: flex;
  padding: 0 10px;
}

.order_card-content {
  height: 242px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 10px;
}

.order_card-flagpriority {
  position: absolute;
  left: 0;
  width: 6px;
  height: 40px;
}

.order_card-title-avatar {
  background: url("../../../../../../assets/media/img/background-order-img.png");
  background-size: contain;
  min-width: 40px;
  height: 40px;
  color: white;
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  font-weight: bold;
}

.order_card-title-data-client-name {
  width: 75vw;
  /* height: 24px; */
  font-family: Raleway;
  font-size: 16.2px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #ffffff;
  overflow: auto;
}

.order_card-title-data-odt_id {
  width: 213px;
  /* height: 16px; */
  font-family: Raleway;
  font-size: 12.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.6);
}

.order_card-title-data-created_at {
  width: 213px;
  height: 16px;
  font-family: Raleway;
  font-size: 12.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.6);
}

.order_card-title-data-price-event_name {
  display: flex;
  margin-top: 5px;
}

.order_card-title-data-price {
  width: 47px;
  height: 24px;
  margin-right: 40px;
  font-family: Raleway;
  font-size: 14.2px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: 0.1px;
  color: #ffffff;
}

.order_card-title-data-event_name {
  font-family: Raleway;
  font-size: 12.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: #1eb980;
  border: 1px solid #1eb980;
  padding: 3px;
  border-radius: 10px;
}

.order_card-title-data-event_name:active {
  color: white;
  border: 1px solid white;
}

.order_card-content-pulse-in-new {
  width: 64px;
  height: 20px;
  font-family: Raleway;
  font-size: 15.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: 0.25px;
  color: cyan;
}

.order_card-content-actions {
  display: flex;
  align-items: center;
}

.order_card-content-index {
  width: 14px;
  height: 16px;
  font-family: Raleway;
  font-size: 12.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.6);
}

.order_card-content-position-routed {
  width: 14px;
  height: 16px;
  font-family: Raleway;
  font-size: 12.2px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: 0.4px;
  color: rgba(255, 255, 255, 0.6);
}

.order_card-content-position-routed:active {
  color: white;
}

.order_card-content-btns {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#order_card-content-btn-bill {
  height: 25px;
  font-family: Raleway;
  font-size: 14.1px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.25px;
  text-align: center;
  color: #1eb980;
}

.popover-list-component {
  width: 130px;
  list-style: none;
  padding: 7px;
  color: white;
  background-color: #2c2c36;
  margin: 0;
}

.list-item-component {
  font-family: Raleway;
  width: 130px;
  height: 30px;
  padding: 5px 0 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: white;
}

.list-item-component:hover {
  color: lightseagreen;
}

.list-item-component:active {
  color: lightskyblue;
}
